import { Box, Card, Collapse } from "@material-ui/core";
import _ from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactSortable } from "react-sortablejs";
import { LightTooltip } from "src/components/common";
import { focusBoardQuickly, sortLayers } from "src/helper";
import { RootState } from "src/redux";
import { setMouseMode } from "src/redux/reducers/boardReducer";
import {
  bulkUpdateLayer,
  setSelectedLayerIds,
  updateLayer,
} from "src/redux/reducers/layerReducer";
import { GroupObjLayerData } from "src/types/common";
import { LayerTypes, MouseModes } from "src/types/enum";
import { BuilderLayerJSON } from "src/types/query";

import { LayerGroupItem } from "../LayerGroupItem";
import { LayerItem } from "../LayerItem";
import { LayerItemProps } from "../LayerItem/LayerItem";
import { PartAction, PartActionItem } from "../PartAction";
import { CustomCardContent, HeaderTitle } from "./PartGroup.style";

type PartGroupProps = {
  filteredLayers: BuilderLayerJSON[];
  title: string;
  disabled?: boolean;
  actions?: PartActionItem[];
  actionProps?: {
    isPopover?: boolean;
    popoverTooltip?: string;
    hideActionLabel?: boolean;
  };
  extraChildren?: React.ReactNode;
  disableLock?: boolean;
  disableDnd?: boolean;
  hoveredLayerJSON: Record<string | number, boolean>;
  onChangeHoverJSONItem: (layerId: string | number, flag: boolean) => void;
  onDoubleClickItem?: () => void;
};

export const PartGroup = ({
  filteredLayers,
  title,
  disabled,
  actions,
  actionProps = {},
  extraChildren,
  disableLock,
  disableDnd,
  hoveredLayerJSON,
  onChangeHoverJSONItem,
  onDoubleClickItem,
}: PartGroupProps) => {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(true);
  const [groupCompression, setGroupCompression] = useState<
    Record<string | number, boolean>
  >({});
  const owner = useSelector((state: RootState) => state.schemeReducer.owner);
  const layerList = useSelector((state: RootState) => state.layerReducer.list);

  const sortedList = useMemo(
    () => sortLayers(filteredLayers, owner?.id, false),
    [filteredLayers, owner]
  );

  // useEffect(() => {
  //   for (let index in sortedList) {
  //     if (sortedList[index].layer_order !== parseInt(index) + 1) {
  //       console.log("updateLayer in PartGroup!");
  //       dispatch(
  //         updateLayer(
  //           {
  //             ...sortedList[index],
  //             layer_order: parseInt(index) + 1,
  //           },
  //           false
  //         )
  //       );
  //     }
  //   }
  // }, [dispatch, layerList.length, sortedList]);

  const handleExpandClick = useCallback(() => {
    setExpanded((preValue) => !preValue);
    focusBoardQuickly();
  }, [setExpanded]);
  const handleChangeLayerOrders = useCallback(
    (list: BuilderLayerJSON[]) => {
      const layers = [];
      for (const index in list) {
        if (list[index]?.layer_order !== parseInt(index) + 1) {
          layers.push({
            id: list[index].id,
            layer_order: parseInt(index) + 1,
          });
        }
      }
      if (layers.length) {
        dispatch(bulkUpdateLayer(layers));
      }
    },
    [dispatch]
  );

  const toggleField = useCallback(
    (id: string | number, field: string) => {
      const layer = layerList.find((item) => item.id === id);
      if (!layer) return;

      dispatch(
        updateLayer({
          id: layer.id,
          [field]: layer[field as keyof typeof layer] ? 0 : 1,
        })
      );
    },
    [layerList, dispatch]
  );
  const selectLayer = useCallback(
    (layer: BuilderLayerJSON) => {
      dispatch(setSelectedLayerIds([layer.id]));
      dispatch(setMouseMode(MouseModes.DEFAULT));
    },
    [dispatch]
  );
  const hoverLayer = useCallback(
    (layer: BuilderLayerJSON, flag: boolean) => {
      onChangeHoverJSONItem(layer.id, flag);
    },
    [onChangeHoverJSONItem]
  );

  const handleToggleGroupCompression = useCallback(
    (groupId: string | number, flag: boolean) => {
      setGroupCompression((preValue) => ({
        ...preValue,
        [groupId]: flag,
      }));
    },
    []
  );

  const LayerItemOrGroup = useCallback(
    (
      props: {
        disableDnd?: boolean;
      } & LayerItemProps
    ) => {
      if (props.layerItem.layer_type === LayerTypes.GROUP) {
        return (
          <LayerGroupItem
            {...props}
            layerItem={props.layerItem as BuilderLayerJSON<GroupObjLayerData>}
          />
        );
      }

      return <LayerItem {...props} />;
    },
    []
  );

  return (
    <Box mb={2}>
      <Card>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          position="relative"
          padding="16px 16px 16px 45px"
          height="52px"
        >
          <LightTooltip title={title} arrow>
            <HeaderTitle>{title}</HeaderTitle>
          </LightTooltip>
          <PartAction
            expanded={expanded}
            actions={!disabled ? actions : undefined}
            onExpandClick={handleExpandClick}
            {...actionProps}
          />
        </Box>
        <Collapse in={expanded}>
          <CustomCardContent>
            <ReactSortable
              list={sortedList}
              setList={handleChangeLayerOrders}
              animation={150}
              sort={!disableDnd && !disabled}
            >
              {sortedList.map((item) => (
                <LayerItemOrGroup
                  key={item.id}
                  layerItem={item}
                  hoveredLayerJSON={hoveredLayerJSON}
                  groupCompression={groupCompression}
                  toggleField={toggleField}
                  onSelect={selectLayer}
                  onDoubleClick={onDoubleClickItem}
                  onHover={hoverLayer}
                  onToggleGroupCompression={handleToggleGroupCompression}
                  disableDnd={disableDnd}
                  disableLock={Boolean(disableLock)}
                  disabled={Boolean(disabled)}
                />
              ))}
            </ReactSortable>
            {extraChildren}
          </CustomCardContent>
        </Collapse>
      </Card>
    </Box>
  );
};

export default PartGroup;
